<template>
    <label class="xs:items-center xs:flex-row flex flex-col gap-x-4 gap-y-2">
        <span class="whitespace-nowrap">
            {{ label }}
        </span>

        <UiFormSelect
            v-model="selected"
            :options="options"
            input-class="!bg-transparent !p-2 max-xs:!pl-0 !rounded-none sim-font-medium"
        />
    </label>
</template>

<script lang="ts" setup generic="T">
import type { SelectOption } from '@core/app/components/core/ui/form/CoreUiFormSelect.vue'

const {
    label,
    options,
} = defineProps<{
    label: string
    options: SelectOption<T>[]
}>()

const selected = defineModel<SelectOption<T>['value']>()

</script>

<style lang="scss" scoped>

</style>
